import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Constant } from "../constants/constant";
import { User } from "../models/user";
import { CommonService } from "./common.service";
import { EcryptServiceService } from "./ecrypt-service.service";

export const TOKEN = "token";
export const AUTHENTICATED_USER = "authenticaterUser";
export const USER_ID = "ID";
export const CARTCOUNT = "CARTCOUNT";
export const AUTHENTICATED_USER_EMAIL = "authenticaterUserEmail";
@Injectable({
  providedIn: "root",
})
export class UserService {
  public responseCache = new Map();
  addressIndex = new Subject<any>();
  userLogin=this.checkLogin();

  loginError: any;
  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private router: Router,
    private commonService: CommonService,
    private route:ActivatedRoute,
    private ecryptServiceService: EcryptServiceService,
  ) {}

  url = environment.serverAPI3 + "/api/customers";
  url1 = environment.serverAPI3 + "/api/ig/customers";
  url2 = environment.serverAPI4 + "/api/location";
  url4 = environment.serverAPI2 + "/api/auth";

  url5 = environment.serverAPI3 + "/" + "api/cart";
  url6 = environment.serverAPI3 + "/" + "api/ig/cart";

  checkLogin() {
    let basicAuthHeaderString = this.getAuthenticatedToken();
    let username = this.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {

      return true;
    }
    else {

      return  false;
    }
  }
  emailVerified(email: any): Observable<Object> {
    return this.http.get(`${this.url4}/customer/username/${email}`);
  }
  deactivate(username: any): Observable<Object> {
    return this.http.get(`${this.url4}/deactivate/${username}`);
  }

  forgotPassword(email: any): Observable<Object> {
    return this.http.get(`${this.url4}/forgetpassword/${email}`);
  }

  verifyForgotPasswordToken(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(
      `${this.url4}/password/verifyToken`,
      user
    );
  }

  changePassword(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url4}/changePassword`, user);
  }

  resetPassword(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url4}/resetPassword`, user);
  }

  getCustomers(): Observable<Object> {
    return this.http.get(`${this.url}`);
  }

  createCustomers(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}`, user);
  }

  updateCustomer(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, user);
  }


  deleteCustomer(addressId: any): Observable<Object> {
		return this.http.delete(`${this.url}/${addressId}`);
	}

  verifyToken(token: string): Observable<Object> {

    let object={
      "key":token
    }
    return this.http.post<Response[]>(`${this.url4}/verify/token`, object);
  
  }

  reverifyToken(token: string): Observable<Object> {
    return this.http.get(`${this.url4}/reverify/${token}`);
  }

  getUsersById(): Observable<Object> {
    return this.http.get(`${this.url}/details`);
  }

  getPincode(pincode: number): Observable<Object> {
    return this.http.get(`${this.url2}/${pincode}`);
  }

  public getUserById(cache: any): Observable<any> {
    return this.http.get(`${this.url}/details`);
  }

  loginFn(username, password) {
    let source = Constant.source;
    let enco: any = new HttpHeaders();

    if (Constant.DEVICE_TYPE != "MOBILE") {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login`,
          {
            username,
            password,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else if (response.body.status_code == "AST-105") {
              return response.body;
            }else {
            this.userLogin=true;
              sessionStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
              sessionStorage.setItem(
                AUTHENTICATED_USER_EMAIL,
              response.body.data.customer.email
            );
              sessionStorage.setItem(
                USER_ID,
                response.body.data.customer.entityId
              );
              sessionStorage.setItem(TOKEN, `${response.body.data.userToken}`);
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              //this.cookieService.set('xsrf-token',`${response.headers.get('xsrf-token')}`)
              //  sessionStorage.setItem(USER_ID, `Bearer ${data.responseObject.userId}`);
              return response.body;
            }
          })
        );
    } else {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login`,
          {
            username,
            password,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else if (response.body.status_code == "AST-105") {
              return response.body;
            } 
            else {
              this.userLogin=true;
              localStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
              localStorage.setItem(
                AUTHENTICATED_USER_EMAIL,
              response.body.data.customer.email
            );
              localStorage.setItem(
                Constant.X_XSRF_TOKEN,
                `${response.body.data.userToken}`
              );
              localStorage.setItem(
                Constant.XSRF_TOKEN,
                `${response.body.data.accessToken}`
              );
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              
              return response.body;
            }
          })
        );
    }
  }

  logout(): any {
    let lat= localStorage.getItem('latitude');
    let long= localStorage.getItem('longitude');
    let title=localStorage.getItem('title');
    sessionStorage.clear();
    let redirectToLoginPage=localStorage.getItem('redirectToLoginPage');
    this.clearCache();
    if (Constant.DEVICE_TYPE == "MOBILE") {
     let currency=localStorage.getItem('currency');
      localStorage.clear();
      localStorage.setItem('currency',currency)
      this.reload();
    } else {
      this.logout1().subscribe(
        response => {
          this.reload();
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            document.cookie = "x-xsrf-token" + "=;expires="
            + new Date(0).toUTCString();
            document.cookie = "user-name" + "=;expires="
            + new Date(0).toUTCString();
            document.cookie = "user-id" + "=;expires="
            + new Date(0).toUTCString();

            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
          }
          else {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
      (err) => { this.reload();} );

    }
    if(lat!=null){
    localStorage.setItem('latitude',lat);
    localStorage.setItem('longitude', long);
    localStorage.setItem('title', title);
    }
    this.userLogin=true;

    if(redirectToLoginPage!=null){
    localStorage.setItem('redirectToLoginPage',redirectToLoginPage)
    }
  }

  logout1(): Observable<Object> {

		let source = Constant.source
		sessionStorage.clear();
		return this.http.get(`${environment.serverAPI2}/api/auth/user/logout/${source}`);
	}


  reload() {
    {


      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";

      if (this.router.url.includes("/pr?sid=")) {
        let value = this.router.url.split("/pr?sid=");

        this.commonService.goToCategoryWithEncryptedKey(
          value[0].slice(0),
          value[1]
        );
      } else if (this.router.url.includes("?sid=")) {
        let value = this.router.url.split("?sid=");


       
        this.commonService.productSearch(this.ecryptServiceService.decryptionAES(value[1]));
      }
      else if(this.router.url.includes('/product/')){
     
        this.router.navigate([this.router.url]);
      }
      else {
        this.router.navigate(['/']);
      }
    }
  }

  reloadmob() {
    this.router.navigate([this.router.url]);
  }

  logoutMobile(): any {
    if (Constant.DEVICE_TYPE == "MOBILE") {
      let lat= localStorage.getItem('latitude');
      let long= localStorage.getItem('longitude');
      let title=localStorage.getItem('title');
      localStorage.clear();
      if(lat!=null){
      localStorage.setItem('latitude',lat);
    localStorage.setItem('longitude', long);
    localStorage.setItem('title', title);
      }
      this.reload();
    }
  }
  getAuthenticatedUser() {
    let userName = null;
    if (Constant.DEVICE_TYPE == "MOBILE") {
      userName = localStorage.getItem("authenticaterUser");
    } else {
      userName = this.getCookie("user-name");
    }
    return userName;
  }

  getAuthenticatedUserEmail() {
    let userName = null;
    if (Constant.DEVICE_TYPE == "MOBILE") {
      userName = localStorage.getItem("authenticaterUserEmail");
    } else {
      userName = this.getCookie("email");
    }
    return userName;
  }

  

  getAuthenticatedToken() {
    let token = null;
    if (Constant.DEVICE_TYPE == "MOBILE") {
      token = localStorage.getItem(Constant.X_XSRF_TOKEN);
    } else {
      token = this.getCookie(Constant.X_XSRF_TOKEN);
    }
    return token;
  }
  getAuthenticatedUserId() {
    let userId = this.getCookie("user-id");
    return userId;
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getStorageToken(i: any) {
    let token ="";
    if(Constant.DEVICE_TYPE == "MOBILE"){
      token = localStorage.getItem(i);
      if (token == null) {
        token = "";
      }
    }else{
      token = this.getCookie(Constant.X_XSRF_TOKEN);
    }
    return token;
  }

  // Load the refresh token from storage
  // then attach it as the header for one specific API call
  getNewAccessToken() {
    const refreshToken = this.getStorageToken(Constant.X_XSRF_TOKEN);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      [Constant.X_XSRF_TOKEN]: this.getStorageToken(Constant.X_XSRF_TOKEN),
      [Constant.XSRF_TOKEN]: this.getStorageToken(Constant.XSRF_TOKEN),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(`${this.url4}/refresh/token `, options);
  }
  currentAccessToken = null;

  storeAccessToken(i) {
    localStorage.setItem(Constant.XSRF_TOKEN, JSON.stringify(i));
    return true;
  }

  storeProductCartCount(i) {
    sessionStorage.setItem(Constant.CART_COUNT, i);
    return true;
  }

  storeCourseSubscribed(i) {
    sessionStorage.setItem(Constant.COURSE_SUBSCRiBED, i);
    return true;
  }


  loginUsingOtp(userName: any): Observable<Response[]> {
    let user={
      username:userName,
      source:Constant.source
    }
    return this.http.post<Response[]>(`${this.url4}/user/login/otp`, user);
  }

  // verifyOtp(userName: any,otp:any): Observable<Response[]> {
  //   let user={
  //     username:userName,
  //     source:Constant.source,
  //     accessToken:otp
  //   }
  //   return this.http.post<Response[]>(`${this.url4}/user/login/otp/verify`, user);
  // }

  verifyOtp(username, accessToken) {
    let source = Constant.source;
    let enco: any = new HttpHeaders();

    if (Constant.DEVICE_TYPE != "MOBILE") {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login/otp/verify`,
          {
            username,
            accessToken,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else {
              this.userLogin=true;
              sessionStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
                 sessionStorage.setItem(
                  AUTHENTICATED_USER_EMAIL,
                response.body.data.customer.email
              );
              sessionStorage.setItem(
                USER_ID,
                response.body.data.customer.entityId
              );
              sessionStorage.setItem(TOKEN, `${response.body.data.userToken}`);
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              //this.cookieService.set('xsrf-token',`${response.headers.get('xsrf-token')}`)
              //  sessionStorage.setItem(USER_ID, `Bearer ${data.responseObject.userId}`);
              return response.body;
            }
          })
        );
    } else {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login/otp/verify`,
          {
            username,
            accessToken,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else {
              this.userLogin=true;
              localStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
              localStorage.setItem(
                AUTHENTICATED_USER_EMAIL,
              response.body.data.customer.email
            );
              localStorage.setItem(
                Constant.X_XSRF_TOKEN,
                `${response.body.data.userToken}`
              );
              localStorage.setItem(
                Constant.XSRF_TOKEN,
                `${response.body.data.accessToken}`
              );
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              return response.body;
            }
          })
        );
    }
  }

  resendOtp(userName: any): Observable<Response[]> {
    let user={
      username:userName,
      source:Constant.source,
    
    }
    return this.http.post<Response[]>(`${this.url4}/user/login/otp/resend`, user);
  }

  customerNumberCheck(userName: any): Observable<Object> {

    return this.http.get(`${this.url4}/verify/telephone/${userName}`);
  
  }



  socialLogin(username: any,firstName:any,lastName) {
    let source = Constant.source;
    let enco: any = new HttpHeaders();

    if (Constant.DEVICE_TYPE != "MOBILE") {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login/social`,
          {
            username,
            firstName,
            lastName,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else {
            this.userLogin=true;
              sessionStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
              sessionStorage.setItem(
                AUTHENTICATED_USER_EMAIL,
              response.body.data.customer.email
            );
              sessionStorage.setItem(
                USER_ID,
                response.body.data.customer.entityId
              );
              sessionStorage.setItem(TOKEN, `${response.body.data.userToken}`);
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              //this.cookieService.set('xsrf-token',`${response.headers.get('xsrf-token')}`)
              //  sessionStorage.setItem(USER_ID, `Bearer ${data.responseObject.userId}`);
              return response.body;
            }
          })
        );
    } else {
      return this.http
        .post<any>(
          `${environment.serverAPI2}/api/auth/user/login/social`,
          {
            username,
            firstName,
            lastName,
            source,
          },
          { observe: "response" }
        )
        .pipe(
          map((response) => {
            if (
              response.body == "" ||
              response == undefined ||
              response == null
            ) {
              this.loginError = "Username or Password is invalid";
              return { error: this.loginError };
            } else if (response.body.status_code == "AST-102") {
              return response.body;
            } else {
              this.userLogin=true;
              localStorage.setItem(
                AUTHENTICATED_USER,
                response.body.data.customer.firstName
              );
              localStorage.setItem(
                AUTHENTICATED_USER_EMAIL,
              response.body.data.customer.email
            );
              localStorage.setItem(
                Constant.X_XSRF_TOKEN,
                `${response.body.data.userToken}`
              );
              localStorage.setItem(
                Constant.XSRF_TOKEN,
                `${response.body.data.accessToken}`
              );
              sessionStorage.setItem(
                CARTCOUNT,
                response.body.data.customer.quoteItemCount
              );
              return response.body;
            }
          })
        );
    }
  }

  generateReferalId(): Observable<Object> {

		return this.http.get(`${this.url4}/generate/referralid`);

	}

  public responseCacheCourse = new Map();

  public getCourseSubscribed(): Observable<any> {

    let authourized = this.getAuthenticatedUser();
    let response: any = null;
    if(authourized){
      const courseFromCache = this.responseCacheCourse.get(
        `${this.url5}/customer/subscribed`
      );

      if (courseFromCache != undefined) {
        return of(courseFromCache);
      }   else {
        response = this.http.get<any>(`${this.url5}/customer/subscribed`);
        response.subscribe((value) =>
          this.responseCacheCourse.set(`${this.url5}/customer/subscribed`, value)
        );
      }
    }
  
    return response;
  }

  getCourseValuesNew() {

    let response: any = null;
    response = this.http.get<any>(`${this.url5}/customer/subscribed`);
    response.subscribe((value) =>
      this.responseCacheCourse.set(`${this.url5}/customer/subscribed`, value)
    );
  }

  getCourseValues() {
    let authourized = this.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    if(authourized){
    this.getCourseSubscribed().subscribe(
      (count) => {
        this.responseCacheCourse.set(`${this.url5}/customer/subscribed`, count)

        return count;
      },
      (err) => {}
    );
  }
  }

  
  getCourseValue(){
    let authourized = this.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    let value=null;
    if(guestLoginEnabled=="0"||authourized){
      value =this.responseCacheCourse.get(
      `${this.url5}/customer/subscribed`
    );
    }else{
      value =this.responseCacheCourse.get(
        `${this.url6}/customer/subscribed`
        );
    }

    return value;
  }

  public clearCache(): void {
    this.responseCacheCourse.clear();
  }


}


