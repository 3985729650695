<div style="width: 100%;" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">

  <mat-form-field fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" appearance="outline" [formGroup]="group">
    <mat-label>{{field.label}}
      <span *ngIf="isRequired(field.name)" class="required-symbol">*</span>
    </mat-label>
    <input matInput [formControlName]="field.name" [type]="field.inputType">

    <ng-container *ngFor="let validation of field['dynamicFormLabelValidator'];" ngProjectAs="mat-error">
      <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>

    <mat-hint *ngFor="let validation of field['dynamicFormLabelValidator'];">{{validation.hintMessage}}</mat-hint>

  </mat-form-field>
  <button fxFlex mat-raised-button *ngIf="customField13 == 1"
    style="margin: 0px 10px; border-radius: 11px;  height: 57px;" color="primary"
    (click)="setValue(group.get(field.name).value)">Submit</button>

</div>