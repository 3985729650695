import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPriceService {

  private subscriptionPriceSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionPrice'));
  private subscriptionTaxPercentageSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTaxPercentage'));
  private subscriptionTotalCostSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalCost'));
  private subscriptionTotalTaxAmount = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalTaxAmount'));
  private subscriptionType = new BehaviorSubject<any>(localStorage.getItem('subscriptionType'));

  private subscriptionImgName = new BehaviorSubject<any>(localStorage.getItem('subscriptionImgName'));

  private amount = new BehaviorSubject<any>(localStorage.getItem('amount'));
  
  private totalGrandTotal = new BehaviorSubject<any>(localStorage.getItem('totalGrandTotal'));

  private layoutConfigMap = new BehaviorSubject<Map<number, number>>(new Map<number, number>());

  
  

  subscriptionPrice$ = this.subscriptionPriceSubject.asObservable();
  subscriptionTaxPercentage$ = this.subscriptionTaxPercentageSubject.asObservable();
  subscriptionTotalCost$ = this.subscriptionTotalCostSubject.asObservable();
  subscriptionTotalTaxAmount$ = this.subscriptionTotalTaxAmount.asObservable();

  GrandTotalAmount$ = this.totalGrandTotal.asObservable();
  subscriptionType$ = this.subscriptionType.asObservable();

  subscriptionImgName$ = this.subscriptionImgName.asObservable();

  amount$ = this.amount.asObservable();

  updateSubscriptionDetails(price: any, taxPercentage: any, totalCost: any,taxAmount:any , title: any) {
    localStorage.setItem('subscriptionPrice', price);
    localStorage.setItem('subscriptionTaxPercentage', taxPercentage.toString());
    localStorage.setItem('subscriptionTotalCost', totalCost.toString());
    localStorage.setItem('subscriptionTotalTaxAmount', taxAmount.toString());
    localStorage.setItem('subscriptionType', title.toString());
    this.subscriptionPriceSubject.next(price);
    this.subscriptionTaxPercentageSubject.next(taxPercentage);
    this.subscriptionTotalCostSubject.next(totalCost);
    this.subscriptionTotalTaxAmount.next(taxAmount);
    this.subscriptionType.next(title);
  }


  updateGrandTotal(totalGrandTotal: any) {
   
    this.layoutConfigMap.next(totalGrandTotal);
  }

  updatesubscriptionImgName(subscriptionImgName: any) {
   
    this.subscriptionImgName.next(subscriptionImgName);
  }

  updateAmount(amount: any) {

    localStorage.setItem('amount', amount);
    this.amount.next(amount);
  }


  private keyValueMap: Map<string, any> = new Map();
  private keyValueSubject: BehaviorSubject<Map<string, any>> = new BehaviorSubject(this.keyValueMap);
 // Check if the map is empty
 isEmpty(): Observable<boolean> {
  return this.keyValueSubject.asObservable().pipe(
    map(map => map.size === 0)
  );
}

// Get a value by key
getValue(key: string): Observable<any> {
  return this.keyValueSubject.asObservable().pipe(
    map(map => map.get(key))
  );
}

// Update a value by key
updateValue(key: string, value: any): void {
  this.keyValueMap.set(key, value);
  this.keyValueSubject.next(this.keyValueMap);
}

// Remove a value by key
removeValue(key: string): void {
  this.keyValueMap.delete(key);
  this.keyValueSubject.next(this.keyValueMap);
}

// Get the entire map
getMap(): Observable<Map<string, any>> {
  return this.keyValueSubject.asObservable();
}

// calculateTax(price: number, taxPercent: number, initialTaxAmount: number, inclusiveTax: number, gst: number): number {
//   let total: number;

//   if (inclusiveTax === 1) {
//     total = (price * taxPercent) / (100 + taxPercent);
//     total = Math.abs(total);

//     if (gst === 1) {
//       total = price - total;
//       total = (total * initialTaxAmount) / 100;
//     }
//   } else {
//     if (gst === 1) {
//       total = (price * initialTaxAmount) / 100;
//     } else {
//       total = (price * taxPercent) / 100;
//     }
//   }

//   return total;
// }

calculateTax(price: any, taxPercent: number, inclusiveTax: number ) {


  let totalCost = price;
  let taxAmount=0.0; 
  if (inclusiveTax==0) {
    taxAmount = (price * taxPercent) / 100;
    
  } else {
    taxAmount = (price*taxPercent)/(100+taxPercent);

   
   
  }

 return taxAmount;
}


// updateFormValue(value: any) {
   
//   this.layoutConfigMap.next(totalGrandTotal);
// }

private formSubject = new BehaviorSubject<any | null>(null);
form$ = this.formSubject.asObservable(); 
updateFormValue(form: any) {

  
  this.formSubject.next(form);
}



  
}
