<mat-toolbar [ngClass]="data.mobileHeaderType==1 ? 'primary' : 'mobileheader'"
style="z-index: 1000;" >
  <mat-toolbar-row style="height: 100%;" fxLayout="row" fxLayoutAlign="space-between center">
      <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

      <calsoft-logo  [sitename]="data.siteName" fxLayoutAlign="start start" fxFlex [component]="'menu'"
        (click)="closeMenu()" [type]="'light'"></calsoft-logo>
     </section>

     <span  class="font" *ngIf="displayNameFully" fxLayoutAlign="center center" fxFlex="50" (click)="account1()" 
     [style.color]="data.headerIconColour">
     Hi {{username}}
        
   </span>
   

      <section   (click)="closeMenu()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">
        <button  fxFlex="20" fxLayoutAlign="end end" (click)="closeMenu()" mat-icon-button>
          <mat-icon [style.color]="data.headerIconColour">close</mat-icon>
        </button>
        

      </section>

  </mat-toolbar-row>
 

</mat-toolbar>

<div *ngIf="data.categoryMenuMobileType==0">
<div  *ngFor="let menu of sideMenuItem;let i = index">

  <div *ngIf="menu.displayMode==6; else notButton">
<!-- 			
    <calsoft-button-category [menuData]="menu"></calsoft-button-category> -->
</div>

<ng-template #notButton>
  <ng-container *ngIf="menu.subcategories.length > 0">

    <a  class="font" [ngStyle]="{'margin-top':i==0 ? '10px' : '0px' }" mat-list-item (click)="categories(menu,i)">
      <img onerror="this.src='assets/images/products/noImage/no-image.png';" style="height: 25px;width: 25px;" [src]="serverPath+menu.image" [alt]="menu.categoryName">
      &nbsp;{{menu.categoryName }}
    </a>

  </ng-container>

  <ng-container *ngIf="menu.subcategories.length== 0">

    <a   class="font" [ngStyle]="{'margin-top':i==0 ? '10px' : '0px' }" mat-list-item (click)="goToCategory(menu)">
      <img  onerror="this.src='assets/images/products/noImage/no-image.png';"style="height: 25px;width: 25px;" [src]="serverPath+menu.image" [alt]="menu.categoryName">
      &nbsp;{{menu.categoryName }}
    </a>
  </ng-container>
  </ng-template>

</div>
</div>

<div *ngIf="data.categoryMenuMobileType==1" >
  <!-- <div   id="catList"  class="container" *ngIf="!display">
    <mat-list   *ngFor="let item of sideMenuItem; let i = index"
      [ngClass]="{'accordion-list':item.subcategories.length ==0,'':item.subcategories.length >0}" lines="none"
       detail="false" no-padding menuClose>
  
      <div matRipple tappable
  
        [ngClass]="{'section-active': item.open, 'section': !item.open}">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-label fxFlex="80"  (click)="productList(item , item.subcategories.length)" 
          
          color="dark" class="itemLabel padding-tlr">
            {{ item.categoryName }}
          </mat-label>
          <mat-icon   (click)="toggleSection(i)" matSuffix *ngIf="item.subcategories.length >0 && item.open !=true">arrow_right</mat-icon>
          <mat-icon  (click)="toggleSection(i)" class="iconClr" *ngIf="item.open ==true">arrow_drop_down</mat-icon>
        </div>
      </div>
      <div    *ngIf="item.subcategories && item.open" menuClose>
        <mat-list  *ngFor="let child of item.subcategories; let j = index" lines="none"  class="itemLabel padding-tlr">
          <div tappable
            *ngIf="child.subcategories" >
            <mat-label>
  
              <div fxLayout="row" fxLayoutAlign="space-between none" matRipple  class="itemLabel padding-tlr">
                <span class="itemLabel" (click)="productList(child , child.subcategories.length)"> {{ child.categoryName }}</span>
                <mat-icon  (click)="toggleItem(i, j)"   matSuffix *ngIf="child.subcategories.length >0 && child.open !=true">
                  arrow_right
                </mat-icon>
                <mat-icon  (click)="toggleItem(i, j)"  *ngIf="child.open ==true">arrow_drop_down</mat-icon>
              </div>
   
            </mat-label>
  
          </div>
  
  
          <div *ngIf="child.subcategories && child.open" class="product-list" lines="none">
            <mat-list *ngFor="let subChild of child.subcategories;  let k = index"  lines="none"
              >
              <div matRipple tappable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-label fxFlex="80"  (click)="productList(subChild , subChild.subcategories.length)" 
                
                color="dark" class="itemLabel padding-tlr">
                  {{ subChild.categoryName }}
                </mat-label>
                <mat-icon  (click)="toggleItemChild(i,j,k)" matSuffix *ngIf="subChild.subcategories.length >0 && subChild.open !=true">arrow_right</mat-icon>
                <mat-icon (click)="toggleItemChild(i,j,k)" class="iconClr" *ngIf="subChild.open ==true">arrow_drop_down</mat-icon>
              </div>
            </div>
              <div *ngIf="subChild.subcategories && subChild.open" class="product-list" lines="none">
              <calsoft-side-bar-menu-child [categoryList]="child.subcategories" [index]="k" [child]="subChild"></calsoft-side-bar-menu-child>
              </div>
            </mat-list>
          </div> 
        </mat-list>
      </div>
  
    </mat-list>
  
  </div> -->
  
  <div *ngIf="sideMenuItem!=undefined" >
    <calsoft-sidebar-menu-new [menuItems]="sideMenuItem" [menuParentId]="'2'"></calsoft-sidebar-menu-new>
</div>
  
</div>


<mat-divider></mat-divider>
<div>
  <a  class="font" mat-list-item *ngIf="data.disableCartIconInHeader==0" (click)="account('/cart')">
    <mat-icon class="primary-icon">add_shopping_cart</mat-icon>
    My Cart
  </a>
  <a  class="font"  mat-list-item *ngIf="data?.disableYourOrders==0" (click)="account('/orders')">
    <mat-icon class="primary-icon">text_snippet</mat-icon>
    My Orders
  </a>
  <a   class="font" mat-list-item  *ngIf="data?.enableMylearning==1" (click)="account('/myCourse')">
    <mat-icon class="primary-icon">text_snippet</mat-icon>
    My Learning
  </a>
  <a  class="font"  mat-list-item  *ngIf="data.disableWishlistHeader==0" (click)="account('/account/wishlist')">
    <mat-icon class="primary-icon">favorite</mat-icon>
    My Wishlist
  </a>
  <a   class="font" mat-list-item *ngIf="data.disableLoginHeader==0"  (click)="account('/account/info')">
    <mat-icon class="primary-icon">person</mat-icon>
    My Account
  </a>
  <a   class="font" mat-list-item *ngIf="data.disableCoupoun==1" (click)="account('/coupon')">
    <mat-icon class="primary-icon">local_offer</mat-icon>
    Coupon
  </a>
  <a   class="font" mat-list-item *ngIf="data.showContactPage==1" (click)="account('/contact-us')">
    <mat-icon class="primary-icon">contact_page</mat-icon>
    Contact-Us
  </a>
  <a  class="font" mat-list-item (click)="account('/aboutus')">
    <mat-icon class="primary-icon">info</mat-icon>
    About-Us
  </a>
  <a  class="font" mat-list-item  *ngIf="data.showBlogPage==1" (click)="account('/blog')">
    <mat-icon class="primary-icon">chat</mat-icon>
    Blog
  </a>
  <a  class="font" mat-list-item *ngIf="data.showBrochurePage==1" (click)="account('/brochure')">
    <mat-icon class="primary-icon">menu_book</mat-icon>
    Brochure
  </a>
</div>

<div  *ngIf="data.enablePolicyInMob==1">
  <calsoft-menu-iteration [data]="data" class="padding-5"></calsoft-menu-iteration>
</div>



<calsoft-menu-iteration2 *ngIf="referer.includes('vgrowfarms.')" class="padding-5"></calsoft-menu-iteration2>
<!-- <button (click)="addToHomeScreen1()">Add to Home Screen</button> -->




<div *ngIf="basicAuthenticationService.userLogin">
  <div (click)="signOut()">
    <a mat-list-item>
      <mat-icon class="primary-icon">logout</mat-icon>
      Logout
    </a>
  </div>
</div>
