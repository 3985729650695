<div *ngIf="display">
    <mat-toolbar class="primary" fxHide fxShow.gt-md>
        <mat-toolbar-row class="theme-container primary"
            style="padding: 0px;color: #ffffff;height: 40px" fxLayout="row"
            fxLayoutAlign="end center" fxLayoutGap="5px">

            <div *ngIf="data.showContactNumber==1" fxLayoutAlign="center center" fxFlex="35">
                <span style="font-size: 16px;">
                    Customer Support:{{data.storePhoneNumber}} &nbsp; | &nbsp; Time : {{data.storeHoursOfOperation}}
                </span>
            </div>


        </mat-toolbar-row>
        <mat-toolbar-row class="theme-container" style="background: #ffffff; height: 100%;" fxLayout="row"
            fxLayoutAlign="start center">

            <div fxFlex="5">

            </div>
            <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
            </section>

            <div fxFlex="7">

            </div>

            <div *ngIf="data.disableSearchInHeader==0" fxLayout="row" fxLayoutAlign="end center">
                <calsoft-search [boxShadow]="'0 0 1px'" [searchbarborderRadius]="'8px'" fxFlex="600px"></calsoft-search>
            </div>

            <div fxFlex="20">

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

                <calsoft-cart-icon [color]="true" [CartName]="'Cart'"></calsoft-cart-icon>

                <div *ngIf="data.disableLoginHeader==0">
                    <button style="color: black" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
                        <mat-icon style="color: black;">person_outline</mat-icon>
                        <span>Profile</span>
                    </button>
                </div>


                <calsoft-HeaderUserProfileDropdown [data]="data" [color]="'white'" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>


            </div>


        </mat-toolbar-row>
        <mat-toolbar-row class="primary theme-container"
            style="padding: 0px ;color: #ffffff;height: -webkit-fill-available" fxLayout="row"
            fxLayoutAlign="center center">
            <calsoft-Menu [color]="true" [data]="data" [type]="'two'" style="z-index: 1000;" class="menu"
                [categoryArray]="item"></calsoft-Menu>

        </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar style="z-index: 1000;" fxHide fxShow.gt-md fxShow.md class="calsoft-fixed-header primary">
        <mat-toolbar-row class="theme-container" style="background: #ffffff; height: 100%;" fxLayout="row"
            fxLayoutAlign="start center">

            <div fxFlex="5">

            </div>
            <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
            </section>

            <div fxFlex="7">

            </div>

            <div *ngIf="data.disableSearchInHeader==0" fxLayout="row" fxLayoutAlign="end center">
                <calsoft-search [boxShadow]="'0 0 1px'" [searchbarborderRadius]="'8px'" fxFlex="600px"></calsoft-search>
            </div>

            <div fxFlex="20">

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

                <calsoft-cart-icon [color]="true" [CartName]="'Cart'"></calsoft-cart-icon>

                <div *ngIf="data.disableLoginHeader==0">
                    <button style="color: black" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
                        <mat-icon style="color: black;">person_outline</mat-icon>
                        <span>Profile</span>
                    </button>
                </div>


                <calsoft-HeaderUserProfileDropdown [data]="data" [color]="'white'" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>


            </div>


        </mat-toolbar-row>
        <mat-toolbar-row class="primary theme-container"
            style="padding: 0px ;color: #ffffff;height: -webkit-fill-available" fxLayout="row"
            fxLayoutAlign="center center">
            <calsoft-Menu [color]="true" [data]="data" [type]="'two'" style="z-index: 1000;" class="menu"
                [categoryArray]="item"></calsoft-Menu>

        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>


</div>

