<div class="header-main-wrapper">
    <nav class="navigation collapse navbar-collapse pull-right">
      <ul class="nav-links nav navbar-nav">
        <li *ngFor="let item of categoryArray" [class.dropdown]="item.subcategories.length > 0" [class.active]="item.link === 'index.html'">
          <a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" (click)="goToCategory(item)" class="main-menu">{{ item.categoryName  | characterPipe }}
            <span  [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" *ngIf="item.subcategories.length > 0" class="fa fa-angle-down icons-dropdown"></span>
          </a>
          <ul *ngIf="item.subcategories.length > 0" class="dropdown-menu nwsipl-dropdown-menu-1">
            <li *ngFor="let child of item.subcategories">
              <a [style.color]="data.categoryColor" (click)="goToCategory(child)" [style.fontSize]="data.categoryFontSize" class="link-page">{{ child.categoryName  | characterPipe }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>