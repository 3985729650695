import { Component, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-menu-iteration3',
  templateUrl: './menu-iteration3.component.html',
  styleUrls: ['./menu-iteration3.component.scss']
})
export class MenuIteration3Component {

  @Input() categoryArray: any;

  @Input() data: any;

  constructor(
    public router: Router,
    private commonService: CommonService,
    private ren: Renderer2

    ) {
  }


  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

}
